(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:PersonLicense
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Foto', Foto);

  function Foto($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/foto/:personId', {personId: '@_personId'}, {
    
      query: {
        method: 'GET',
        isArray: true
    }
  });
  }
}());


